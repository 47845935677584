


<template>
  <!-- <img src="@/assets/I_love_you.png" class="hand-ly" />
<div class="wrapp-hand-footer"></div> -->
  <div
    id="footer"
    class="Footer"
    :style="`background-color: ${isCom ? '#FFC634' : '#00cdbc'}`"
  >
    <v-container>
      <v-row>
        <v-col cols="12" sm="4" md="4" lg="4">
          <div class="logo-redes">
            <img
              src="@/assets/logo-compras.svg"
              alt=""
              v-if="isCom"
              srcset=""
            />
            <img src="@/assets/logoMenublanco.svg" alt="" v-else />
            <v-divider vertical></v-divider>
            <!-- Generator: Adobe Illustrator 25.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 124.4 46.6"
              class="svg-logo-by"
              style="enable-background: new 0 0 124.4 46.6"
              xml:space="preserve"
            >
              <g id="Group_25" transform="translate(-121.409 -397.688)">
                <g class="st0">
                  <path
                    class="blancooooo"
                    d="M122,403.7v-4.9h2.1c1,0,1.6,0.7,1.6,1.6c0,0.9-0.6,1.6-1.6,1.6h-1.1v1.7H122z M124,401.1
			c0.4,0,0.6-0.3,0.6-0.7c0-0.4-0.2-0.7-0.6-0.7h-0.9v1.5H124z"
                  />
                  <path
                    class="blancooooo"
                    d="M128.7,398.7c1.4,0,2.5,1.1,2.5,2.5s-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5S127.3,398.7,128.7,398.7z
			 M128.7,402.8c0.8,0,1.5-0.6,1.5-1.5s-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.6-1.5,1.5S127.9,402.8,128.7,402.8z"
                  />
                  <path
                    class="blancooooo"
                    d="M136.2,401.8l0.9-3h1.1l-1.5,4.9h-0.9l-0.9-3l-0.9,3h-0.9l-1.5-4.9h1.1l0.9,3l0.9-2.8h0.8L136.2,401.8z"
                  />
                  <path
                    class="blancooooo"
                    d="M138.9,403.7v-4.9h3.3v0.9H140v1.2h1.9v0.9H140v1.1h2.2v0.9H138.9z"
                  />
                  <path
                    class="blancooooo"
                    d="M145.8,401.9c0.1,0.5,0.3,0.9,0.8,0.9c0.2,0,0.4-0.1,0.5-0.2l0.4,0.8c-0.3,0.3-0.6,0.4-1.1,0.4
			c-1.2,0-1.5-1.3-1.6-1.8h-0.7v1.7h-1.1v-4.9h2.2c1,0,1.6,0.7,1.6,1.6C146.8,401.1,146.4,401.7,145.8,401.9z M145.1,401.1
			c0.4,0,0.6-0.3,0.6-0.7c0-0.4-0.2-0.7-0.6-0.7h-1v1.4H145.1z"
                  />
                  <path
                    class="blancooooo"
                    d="M148,403.7v-4.9h3.3v0.9h-2.2v1.2h1.9v0.9h-1.9v1.1h2.2v0.9H148z"
                  />
                  <path
                    class="blancooooo"
                    d="M154,403.7h-1.8v-4.9h1.8c1.6,0,2.6,1.1,2.6,2.5S155.6,403.7,154,403.7z M154,399.6h-0.7v3.2h0.7
			c0.9,0,1.5-0.7,1.5-1.6C155.5,400.4,155,399.6,154,399.6z"
                  />
                  <path
                    class="blancooooo"
                    d="M162,401.1c0.6,0.1,1,0.7,1,1.2c0,0.8-0.7,1.4-1.7,1.4h-2.1v-4.9l1.8,0c1,0,1.7,0.5,1.7,1.3
			C162.8,400.5,162.5,401,162,401.1z M160.7,400.8c0.8,0,0.9-0.3,0.9-0.7c0-0.4-0.3-0.5-0.6-0.5h-0.8v1.2H160.7z M160.3,401.6v1.2h1
			c0.4,0,0.6-0.2,0.6-0.5c0-0.3-0.1-0.7-1-0.7H160.3z"
                  />
                  <path
                    class="blancooooo"
                    d="M165.5,400.7l1.2-1.9h1.3v0.1l-1.9,2.8v2H165v-2l-1.9-2.8v-0.1h1.3L165.5,400.7L165.5,400.7z"
                  />
                </g>
                <g id="Group_15" transform="translate(122.193 401.661)">
                  <g id="Group_14" transform="translate(0 0)">
                    <path
                      class="blancooooo"
                      d="M122.6,20.8c-0.4-1-1-2-1.6-2.9c0,0,0,0,0,0c-1.1-1.5-2.6-2.7-4.4-3.4c-0.1,0-0.2-0.1-0.3-0.1
				c-0.6-0.2-1.2-0.4-1.8-0.5c-0.8-0.1-1.6-0.2-2.3-0.1h0c-0.2,0-0.4,0-0.6,0.1c-2.8,0.2-5.3,1.5-7.1,3.7c-0.2,0.4-0.2,0.9,0,1.3
				c0.2,0.3,0.7,0.5,1.1,0.4c2.7-0.7,5.5,0.3,7.1,2.7c2,3,1.2,7-1.8,9c-1,0.7-2.2,1-3.4,1.1h-0.6c-1.5-0.1-3-0.7-4.1-1.8
				c-3.4-3.2-3.6-8.9-1.6-12.9c0.2-0.4,0.4-0.7,0.5-1c1.4-2.1,3.4-3.8,5.8-4.7c0,0,0.7-0.2,1.1-0.3c0.5-0.1,0.8-0.5,0.8-1
				c0-0.5-0.4-1-1-1.1c-0.5-0.1-1-0.1-1.5-0.1C99.6,9.1,93.2,14,91,20.8C88.8,13.4,82.5,9,75.3,9c-1.5,0-3,0.2-4.4,0.8
				c-0.6,0.2-1.2-0.1-1.4-0.7c0-0.1-0.1-0.2-0.1-0.4V1.1c0-0.6-0.5-1.1-1.1-1.1h-9c-0.6,0-1.1,0.5-1.1,1.1v11.1
				C55.3,10.1,51.8,9,48.3,9c-7.3,0-13.5,4.8-15.8,11.4C30.2,13.8,24,9.1,16.6,9.1C7.4,9.1,0,16.6,0,25.9c0,9.2,7.5,16.6,16.7,16.6
				h0.1c7.3,0,13.5-4.7,15.7-11.3c2.2,6.6,8.5,11.3,15.9,11.3c3.5,0,7-1,9.8-3.1v2c0,0.6,0.5,1.1,1.1,1.1h9c0.6,0,1.1-0.5,1.1-1.1
				V25.9c-0.2-3.2,2.2-5.9,5.3-6.1c0.2,0,0.4,0,0.5,0c3.8,0,6.2,2.7,6.2,6.9v14.7c0,0.6,0.5,1.1,1.1,1.1h8.2c0.6,0,1.1-0.5,1.1-1.1
				l0-8.5c2.7,5.8,8.6,9.7,15.4,9.7l0,0c1.4,0,2.7-0.2,4-0.5c0.1,0,0.3-0.1,0.4-0.1c2.2-0.6,4.3-1.6,6.1-3.1c2-1.6,3.6-3.7,4.6-6.1
				C124,29,124.1,24.7,122.6,20.8z M110.4,18.2c-1.4,0.5-2.7,0.1-3-0.8c0,0,0,0,0-0.1c-0.2-0.9,0.7-1.9,2-2.3
				c1.3-0.4,2.5-0.2,2.9,0.6c0,0,0,0.1,0.1,0.1C112.7,16.7,111.8,17.8,110.4,18.2z M16.7,32.1c-3.4,0-6.2-2.8-6.2-6.2
				c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2C22.9,29.3,20.1,32.1,16.7,32.1z M58.2,35.6L53.5,31c-0.4-0.4-1.1-0.4-1.5-0.1
				c-1,0.8-2.3,1.2-3.6,1.2c-3.4,0-6.2-2.8-6.2-6.3c0-1.3,0.4-2.7,1.2-3.7c2.1-2.8,6-3.3,8.7-1.2c0.5,0.3,1.1,0.3,1.5-0.1l4.6-4.7
				V35.6z M114.8,17.8c-0.3,0.1-0.6-0.1-0.6-0.3c0-0.1,0-0.1,0-0.2c0.1-0.2,0.3-0.3,0.5-0.3c0.2,0,0.4,0,0.6,0.1
				c0,0,0.1,0.1,0.1,0.2C115.4,17.6,115.2,17.8,114.8,17.8z M114.3,16.6c-0.7,0-1.3-0.4-1.3-0.9v-0.1c0-0.5,0.6-0.8,1.3-0.8h0.1
				c0.5,0,0.9,0.2,1.2,0.5c0.1,0.1,0.1,0.3,0.1,0.5C115.6,16.3,115,16.6,114.3,16.6z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="wrapp" cols="12" sm="6" md="6" lg="6">
          <div class="terminos-redes">
            <div class="redes">
              <p class="mb-0">
                Síguenos en:
                <a target="_blank" href="https://www.instagram.com/menu.hn/">
                  <v-icon color="#fff">mdi-instagram</v-icon>
                </a>
              </p>
              <div class="iconos-redes">
                <!-- <a target="_blank" href="https://www.facebook.com/ochoapp/">
                  <v-icon color="#fff">mdi-facebook</v-icon>
                </a> -->

                <!-- <a
                  target="_blank"
                  href="https://www.linkedin.com/company/ochoapp"
                >
                  <v-icon color="#fff">mdi-linkedin</v-icon></a
                > -->
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- <div class="copyright">
							© 2021 OchoApp						</div> -->
    </v-container>
  </div>
</template>

<script>
import store from "@/store/index.js";
// import descarga from "./btnDescarga";
export default {
  name: "Footer",
  components: {
    // descarga,
  },
  props: ["isCom"],
  methods: {
    alert_d() {
      alert("djhndjhdf");
    },
  },
  computed: {
    cambioState() {
      return store.getters.stadocambio;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
$Naranja: #00cdbc;

@import "@/_responsive.scss";

.color-bg-primary {
}

.Footer {
  width: 100%;
  height: 200px;

  display: flex;

  // background-image: url("../assets/logoMenu.svg");
  background-position: center 20px;
  background-size: 300px;
  background-repeat: no-repeat;
  justify-content: space-between;
  z-index: 50 !important;
  overflow: hidden;
  align-items: center;
  padding-top: 20px;
  border-bottom: #0e1231 20px solid;
  @include responsive(mobile) {
    flex-direction: column;
    background-position: 50% 10px;
    height: auto;
    padding-bottom: 20px;
    justify-content: center;
  }
  .logo-redes {
    width: 300px;
    height: auto;
    // background-color: rgba(159, 22, 22, 0.208);
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      width: 100%;
      height: 100%;
    }
    @include responsive(desktop) {
      width: 300px !important;
      margin-left: 0px;
      justify-content: space-between;

      img {
        width: 120px;
      }
    }
    @include responsive(mobile) {
      width: 100% !important;
      margin-left: 0px;
      justify-content: space-evenly;

      img {
        width: 100px;
      }
    }
    img {
      width: 170px;
    }

    .redes {
      display: flex;
      color: white;
      font-family: "Poppins-Light";
      align-items: center;
    }
  }
}
p {
  font-family: sans-serif;
}
.wrapp {
  display: flex;
  justify-content: flex-end;

  @include responsive(mobile) {
    display: block;
    margin-top: 10px;
  }
}
.terminos-redes {
  @include responsive(mobile) {
    // display: none;
  }
  font-family: "Poppins-Light";
  color: white;
  width: 200px;

  .links {
    a {
      text-decoration: none;
      color: white;
      &:hover {
        opacity: 0.5;
      }
    }
    @include responsive(desktop) {
      width: 200px;
      margin-right: 20px;
    }

    @include responsive(mobile) {
      text-align: center;
    }

    // @include responsive(mobile) {
    //   padding: 5px;
    // }
  }
  .redes {
    @include responsive(mobile) {
      margin-top: 10px;
      text-align: center;
    }

    width: 100%;
    .iconos-redes {
      @include responsive(mobile) {
        justify-content: space-around;
        align-items: center;
        display: flex;
        width: 100%;
      }
      width: 45%;
      display: flex;
      font-size: 25px;
      justify-content: space-between;
      a {
        text-decoration: none;
        color: white;
        transition: 0.3s ease-in-out;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}
.wrapp {
  @include responsive(mobile) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }
}
.CTA {
  display: flex;

  flex-direction: column;
  align-items: flex-end;
  align-items: flex-start;

  @include responsive(mobile) {
    margin-right: 0px;
    margin-top: 20px;
  }
  @include responsive(desktop) {
    margin-right: 20px;
    margin-top: 20px;
  }
  .sistema {
    font-family: "Poppins-Light";
    display: flex;
    color: white;
    font-size: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include responsive(mobile) {
      display: none;
    }
    i {
      margin-left: 5px;
    }
  }
}

.st0 {
  enable-background: new;
}
.svg-logo-by {
  width: 100px;
}
.blancooooo {
  fill: #fff;
}
</style>
