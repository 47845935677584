<template >
  <v-container class="wrap-phone-animations">
    <div class="phone-App-animations">
      <img src="@/assets/landing/phone.png" class="principal" />

      <img src="@/assets/landing/cap.png" class="phone" />
      <img src="@/assets/landing/cap-2.png" class="phone_dos" />

      <div class="text-logo">
        <img src="@/assets/logoMenu.svg" width="150px" class="mb-2 d-xs-none" />

        <h2 class="mb-2">
          Tu tienda <span class="color-primary">digital</span> en minutos
        </h2>
        <!-- <p class="text-ordenalo">{{ $t("text-ordenalo") }}</p> -->
        <!-- <p class="text-ordenalo">
          Personaliza tu restaurante con tu logo y tus colores, comparte con tus
          clientes el tracking de cada orden.
        </p> -->
      </div>

      <!-- <div class="phones-wrapp" >
       
      </div> -->
    </div>
  </v-container>
</template>
<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import store from "@/store/index.js";
gsap.registerPlugin(ScrollTrigger);
export default {
  name: "Phone",
  props: {
    // className: String,
    // text: String,
  },

  data() {
    return {};
  },
  watch: {
    // cambioState(val) {
    //   this.$i18n.locale = val;
    //   this.text_btn = val;
    // },
  },
  methods: {
    startAnimations: function () {
      const scrollTrigger = {
        trigger: ".phone-App-animations",
        pin: true,
        pinSpacing: false,
        snap: { snapTo: [1], duration: 1, delay: 0 },
        markers: false,
        start: " top center",
        end: "bottom bottom",
        scrub: true,
      };

      // console.debug(screen.width);

      if (screen.width <= 770) {
        gsap
          .timeline({ scrollTrigger })

          .from(".phone", {
            x: "-170%",
            width: "80%",
            ease: "inOut",
            opacity: 1,
            duration: 2,
          })
          .from(".phone_dos", {
            x: "170%",
            ease: "inOut",
            opacity: 1,
            width: "80%",
            duration: 2,
          })
          .from(".principal", {
            x: "-150%",
            ease: "inOut",
            opacity: 1,
            duration: 2,
          });
      } else if (screen.width >= 771) {
        gsap
          .timeline({ scrollTrigger })

          .to(".phone", { x: "-250px", ease: "inOut", opacity: 1, duration: 2 })
          .to(".phone_dos", {
            x: "250px",
            ease: "inOut",
            opacity: 1,
            duration: 2,
          });
      }
    },
  },
  computed: {
    // cambioState() {
    //   return store.getters.stadocambio;
    // },
  },
  mounted: function () {
    this.startAnimations();
  },
};
</script>

<style lang="scss" scoped>
@import "@/_responsive.scss";
.color-primary {
  color: #00cdbc;
}
.wrap-phone-animations {
  overflow: hidden;
  @include responsive(mobile) {
    margin-bottom: 0px;
    height: auto;

  }
}
.d-xs-none {
  @include responsive(mobile) {
    display: none;
  }
}
.phone-App-animations {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
  height: 2000px;
  @include responsive(desktop) {
  padding-top: 70px;
  }
  @include responsive(mobile) {
    text-align: center;
    padding-top: 80px;
  }
}
.text-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column !important;
  @include responsive(mobile) {
    text-align: center;
  }
}
.principal {
  z-index: 4;
  top: 200px;
  position: absolute;
  width: 300px;
  @include responsive(desktop) {
    opacity: 1;
    width: 230px;
    top: 160px;
  }
  @include responsive(mobile) {
    opacity: 1;
    width: 280px;
    top: 120px;
  }
}

@include responsive(mobile) {
  .pin-spacer {
    min-height: 2000px !important;
      overflow: hidden;
  }
}
.phone {
  position: absolute;
  width: 230px;
  top: 250px;
  border-radius: 1em;
  box-shadow: #00000027 0px 10px 30px;
  @include responsive(mobile) {
    opacity: 1;
    top: 180px;
    width: 200px;
  }
  @include responsive(desktop) {
    opacity: 1;
    width: 180px;
    top: 190px;
  }
  // transform: translateX(-250px);
}
.phone_dos {
  position: absolute;
  width: 230px;
  top: 250px;
  border-radius: 1em;
  box-shadow: #00000027 0px 10px 30px;
  @include responsive(mobile) {
    opacity: 1;
    top: 180px;
    width: 200px;
  }
  @include responsive(desktop) {
    opacity: 1;
    width: 180px;
    top: 190px;
  }
}
</style>