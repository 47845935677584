<template>
  <div class="table-check my-12">
    <table class="table_">
      <tr class="tr">
        <th></th>
        <th>
          Básico <span style="color: #00cdbc">L 350.00</span> x mes <br />
          <span class="font-weight-light"> (gratis 6 meses)</span>
        </th>
        <th>
          Ninja <span style="color: #00cdbc">L 800.00</span> x mes <br />
          <span class="font-weight-light"> (gratis 6 meses)</span>
        </th>
      </tr>
      <tr class="tr-item" v-for="(i, index) in datos" :key="index">
        <td class="pl-3 font-weight-light">{{ i.title }}</td>
        <td class="td">
          <v-icon color="#00cdbc" v-if="i.pro == true">fa-solid fa-check</v-icon>
          <v-icon color="#ff5900" v-else-if="i.pro === false">mdi-close</v-icon>
          <span v-else>{{ i.pro }}</span>
        </td>
        <td class="td">
          <v-icon color="#00cdbc" v-if="i.ninja === true">fa-solid fa-check</v-icon>
          <v-icon color="#ff5900" v-else-if="i.ninja === false"
            >mdi-close</v-icon
          >
          <span v-else>{{ i.ninja }}</span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "table-",
  components: {},
  props: {},
  data() {
    return {
      datos: [
        { title: "Comisión x venta", pro: "0%", ninja: "0%" },
        { title: "Cantidad Usuarios", pro: "2", ninja: "5" },
        { title: "Personaliza con tu logo y colores", pro: true, ninja: true },
        { title: "Cantidad Categorías", pro: "Ilimitado", ninja: "limitado" },
        { title: "Cantidad Productos", pro: "250", ninja: "500" },
        { title: "Espacio Almacenamiento", pro: "1GB", ninja: "2GB" },
        { title: "Delivery envío a domicilio", pro: false, ninja: true },
        { title: "Pickup recoger en el restaurante", pro: true, ninja: true },
        { title: "Dine In Servir en mesa", pro: true, ninja: true },
        { title: "Recibir pedidos por Whatsapp", pro: true, ninja: true },
        { title: "Recibir pedidos por App", pro: true, ninja: true },
        { title: "Recibir pedidos por Portal Web", pro: true, ninja: true },
        {
          title:
            "App Motoristas enrutamiento desde el restaurante hasta el punto de entrega",
          pro: false,
          ninja: true,
        },
        {
          title: "Admin Panel portal web para gestión de productos y reportes",
          pro: true,
          ninja: true,
        },
        { title: "Módulo Clientes", pro: true, ninja: true },
        { title: "Módulo Descuentos", pro: true, ninja: true },
        {
          title: "Módulo SMS envío de mensajitos a tus clientes",
          pro: false,
          ninja: true,
        },
        { title: "Módulo Sorteos Givaways", pro: false, ninja: true },
        { title: "Módulo Pagos Acepta pagos en línea", pro: true, ninja: true },
      ],
    };
  },
  computed: {},

  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "@/_responsive.scss";
.table-check {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  table {
    width: 90%;
    border-radius: 15px;
    border: none;
    background-color: #ffffff;
    padding: 5px;
    box-shadow: #00453f1d 0px 0px 30px;
    overflow: hidden;
    @include responsive(mobile) {
      width: 95%;
    }
    .tr {
      height: 50px;
      color: #000000;
      @include responsive(mobile) {
        font-size: 12px;
      }
    }
    .tr-item {
      background-color: #ffffff;
      height: 50px;
      .td {
        padding-left: 20px;
        width: 250px !important;
        background-color: #f2f2f26f;
        border-radius: 5px;
        text-align: center;
          @include responsive(mobile) {
          width: 100px !important;
          padding-left: 0px;
        }
      }
      td {
        @include responsive(mobile) {
          font-size: 12px;
        }
      }
    }
  }
}
.table_ {
  margin-top: 100px;
}
</style>
